import 'element-closest';
import 'whatwg-fetch';
import 'regenerator-runtime/runtime';
import CustomEvent from 'custom-event';
import serialize from 'form-serialize';
import { Loader } from 'google-maps';
import { forEach } from '../../../includes/js/helpers';

/**
 * Object containing all Locator functionality.
 *
 * @since 1.0.0
 *
 * @type {Object}
 */
export default class EmLocator {
	/**
	 * Plugin settings.
	 * @type {Object}
	 */
	settings = { ...window.emLocatorSettings }

	/**
	 * Placeholder for all active map markers.
	 * @type {Array}
	 */
	markers = []

	/**
	 * Placeholder for all active map infowindows.
	 * @type {Array}
	 */
	infowindows = []

	activeInfowindow = false

	/**
	 * Track the search input's previous value for comparison.
	 */
	currentSearchValue = ''

	/**
	 * Keep track of the current lat/lng coordinates.
	 */
	currentPosition = {
		lat: false,
		lng: false,
	}

	/**
	 * Keep track of pagination variables returned from REST Response.
	 */
	pagination = {
		totalPosts: 0,
		totalPages: 0,
		currentPage: 0,
		perPage: parseInt(window.emLocatorSettings.postsPerPage),
	}

	/**
	 * Initialize all Locator functionality.
	 *
	 * @since 1.0.0
	 */
	constructor(locatorEl) {
		this.locatorEl = locatorEl;
		this.getGlobalElements();
		this.initMapLayout();
		this.initListLayout();
		this.initBasicAuth();
		this.initShareMethods();
		this.initPagination();
		this.initGoogleMaps();
	}

	/**
	 * Get all relevent UI elements.
	 *
	 * @since 1.0.0
	 */
	getGlobalElements = () => {
		this.searchEl = this.locatorEl.querySelector('.js-eml-search');
		this.mapReset = this.locatorEl.querySelector('.js-eml-reset-map');
		this.predictionsEl = this.locatorEl.querySelector('.js-eml-predictions');
		this.searchAlertEl = this.locatorEl.querySelector('.js-eml-search-alert');
		this.paginationEls = this.locatorEl.querySelectorAll('.js-eml-pagination');
		this.loadMoreButtons = this.locatorEl.querySelectorAll('.js-eml-load-more-button');
	}

	/**
	 * Initialize the Map layout.
	 *
	 * @since 1.1.0
	 */
	initMapLayout = () => {
		this.mapContainerEl = this.locatorEl.querySelector('.js-eml-map-container');
		if (this.isMapEnabled()) {
			this.mapLocationsEl = this.locatorEl.querySelector('.js-eml-map-locations');
			this.mapLocationDetailsEl = this.locatorEl.querySelector('.js-eml-map-location-details');
			this.mapBackButtonEl = this.locatorEl.querySelector('.js-eml-map-back-button');
			this.mapEl = this.locatorEl.querySelector('.js-eml-map');
			this.mapLayoutControl = this.locatorEl.querySelector('.js-eml-map-layout-control');

			if (this.mapLayoutControl) {
				this.mapLayoutControl.addEventListener('click', this.showMapLayout);
			}
		}
	}

	/**
	 * Show the map layout and hide the list layout.
	 *
	 * @since 1.0.0
	 */
	showMapLayout = () => {
		this.locatorEl.classList.add('show-map');
		this.locatorEl.classList.remove('show-list');
		this.map.fitBounds(this.map.bounds);
	}

	/**
	 * Initialize the List layout.
	 *
	 * @since 1.1.0
	 */
	initListLayout = () => {
		this.listLocationsEl = this.locatorEl.querySelector('.js-eml-list-locations');
		this.listLayoutControl = this.locatorEl.querySelector('.js-eml-list-layout-control');

		if (this.listLayoutControl) {
			this.listLayoutControl.addEventListener('click', this.showListLayout);
		}
	}

	/**
	 * Show the list layout and hide the map layout.
	 *
	 * @since 1.0.0
	 */
	showListLayout = () => {
		this.locatorEl.classList.remove('show-map');
		this.locatorEl.classList.add('show-list');
	}

	/**
	 * Update apiUrl with basic auth credientials, if available.
	 *
	 * @since 1.0.0
	 */
	initBasicAuth = () => {
		const { basicAuthUsername: user, basicAuthPassword: pass } = this.settings;
		if (user && pass) {
			this.fetchHeaders = {
				headers: {
					Authorization: `Basic ${btoa(`${user}:${pass}`)}`,
				},
			};
		} else {
			this.fetchHeaders = {};
		}
	}

	/**
	 * Check plugin settings to see which sharing methods are enabled,
	 * and setup booleans and event handlers.
	 *
	 * @since 1.0.0
	 */
	initShareMethods = () => {
		if (this.settings.shareMethods) {
			/**
			 * Extract share methods for easier use.
			 */
			const { download, email, print } = this.settings.shareMethods;

			/**
			 * Create booleans that tell which share methods are enabled for other functions to access.
			 */
			this.downloadEnabled = download !== undefined || false;
			this.emailEnabled = email !== undefined || false;
			this.printEnabled = print !== undefined || false;
			this.shareEnabled = this.downloadEnabled || this.emailEnabled || this.printEnabled;

			/**
			 * Get all share controls and add event listeners.
			 */
			this.shareControls = this.locatorEl.querySelectorAll('.js-eml-share-control');
			forEach(this.shareControls, (i, control) => {
				control.addEventListener('click', this.handleShareControlClick);
			});

			if (this.emailEnabled) {
				this.emailFormContainer = this.locatorEl.querySelector('.js-eml-email-form-container');
				this.emailFormContainer.addEventListener('click', this.handleEmailFormClose);

				this.emailFormCloseButton = this.locatorEl.querySelector('.js-eml-email-form-close-button');
				this.emailFormCloseButton.addEventListener('click', this.handleEmailFormClose);

				this.emailForm = this.locatorEl.querySelector('.js-eml-email-form');
				this.emailForm.addEventListener('submit', this.handleEmailFormSubmit);

				this.locatorEl.addEventListener('click', this.handleEmailFormControlClick);

				this.emailFormAlert = this.locatorEl.querySelector('.js-eml-email-form-alert');
			}
		}
	}

	/**
	 * Initialize pagination functionality.
	 *
	 * @since 1.0.0
	 */
	initPagination = () => {
		forEach(this.loadMoreButtons, (i, loadMoreButton) => {
			loadMoreButton.addEventListener('click', this.handleLoadMore);
		});
	}

	/**
	 * Initialize Google Maps.
	 *
	 * @since 1.0.0
	 */
	initGoogleMaps = async () => {
		const options = {
			libraries: ['places'],
		};
		const loader = new Loader(this.settings.googleMapsApiKey, options);
		this.google = await loader.load();

		this.initSearch();
		this.getLocations();
		this.geolocate();

		if (this.isMapEnabled()) {
			this.initMap();
		}
	}

	/**
	 * Initialize Google's AutocompleteService and Geocoder classes for later use,
	 * and register all search related event listeners.
	 *
	 * @since 1.0.0
	 */
	initSearch = () => {
		this.autocompleteService = new this.google.maps.places.AutocompleteService();
		this.geocoder = new this.google.maps.Geocoder();

		this.searchEl.addEventListener('focusin', this.handleInputFocus);
		this.searchEl.addEventListener('blur', this.handleInputBlur);
		this.mapReset.addEventListener('click', this.handleResetClick);
		document.addEventListener('keydown', this.handleKeyboardNavigation);
	}

	/**
	 * Initialize the map.
	 *
	 * @since 1.0.0
	 */
	initMap = () => {
		const mapSettings = {
			zoom: parseInt(this.settings.zoomLevel),
			center: new this.google.maps.LatLng(this.settings.centerLat, this.settings.centerLng),
		};

		if (this.settings.googleMapsTheme) {
			mapSettings.styles = JSON.parse(this.settings.googleMapsTheme);
		}

		this.map = new this.google.maps.Map(this.mapEl, mapSettings);

		this.google.maps.event.addListener(this.map, 'idle', () => {
			this.mapCenter = this.map.getCenter();
		});

		this.google.maps.event.addDomListener(window, 'resize', () => {
			this.google.maps.event.trigger(this.map, 'resize');
		});

		this.google.maps.event.addListener(this.map, 'resize', this.recenterMap);

		/**
		 * Setup map sidebar events.
		 */
		this.mapLocationsEl.addEventListener('click', this.handleMapLocationClick);
		this.mapBackButtonEl.addEventListener('click', this.hideMapLocationDetails);
	}

	/**
	 * Use the Geolocation API to get the user's current location in order to
	 * center the map, and set the Autocomplete bounds. Position is stored in
	 * localStorage, so that on subsequent views, the map will instantly load
	 * in the right location.
	 *
	 * @since 1.0.0
	 */
	geolocate = () => {
		/**
		 * Make sure the user's browser supports geolocation before continuing.
		 */
		if (navigator.geolocation) {
			if (localStorage.emlUserPosition) {
				/**
				 * Check if emlUserPosition is in localStorage, and if so,
				 * use that to show locations close to the user's location.
				 *
				 * @see https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
				 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse
				 */
				this.userPosition = JSON.parse(localStorage.emlUserPosition);
				this.useCurrentPosition();
			} else {
				/**
				 * Otherwise, use the Geolcation API to get the users position.
				 * Then save that position into localStorage for subsequent page views.
				 *
				 * @see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition
				 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify
				 */

				/**
				 * Add loading class and message to components.
				 */
				this.searchEl.value = 'Loading results near your location...';
				this.searchEl.setAttribute('disabled', 'disabled');
				this.locatorEl.classList.add('loading');

				/**
				 * Remove loading class and message from components.
				 */
				const stopLoading = () => {
					this.searchEl.value = '';
					this.searchEl.removeAttribute('disabled');
					this.locatorEl.classList.remove('loading');
				};

				/**
				 * Function to run when geolocation has run successfully.
				 * @param {Position} position
				 */
				const success = (position) => {
					stopLoading();

					/**
					 * Take only the values we need from the 'position' object, since,
					 * for some reason, it can't be converted to a string using JSON.stringify.
					 * @type {Object}
					 */
					this.userPosition = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
						accuracy: position.coords.accuracy,
					};

					this.currentPosition = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};

					this.useCurrentPosition();

					/**
					 * Save the user's current position for later use.
					 */
					localStorage.setItem('emlUserPosition', JSON.stringify(this.userPosition));
				};

				/**
				 * Function to run when geolocation fails.
				 * @param {PositionError} error
				 */
				const error = () => {
					stopLoading();
				};

				navigator.geolocation.getCurrentPosition(success, error);
			}
		}
	}

	/**
	 * Update the map and results using the user's current location.
	 *
	 * @since 1.0.0
	 */
	useCurrentPosition = () => {
		this.getLocations(this.userPosition.lat, this.userPosition.lng);
		this.searchEl.value = 'Currently using your location. Click to change.';
		this.searchEl.classList.add('has-current-location-message');
	}

	/**
	 * Clear out the "current location" placeholder message from the input on focus.
	 *
	 * @since 1.0.0
	 */
	handleInputFocus = () => {
		if (this.searchEl.classList.contains('has-current-location-message')) {
			this.searchEl.value = '';
			this.searchEl.classList.remove('has-current-location-message');
		}
	}

	/**
	 * Remove classes from the searchEl and predictionsEl when the searchEl loses focus.
	 *
	 * @since 1.0.0
	 */
	handleInputBlur = () => {
		if (!this.hasResults()) {
			this.showNoResultsAlert();
		} else {
			this.searchAlertEl.innerHTML = '';
		}
		setTimeout(() => {
			this.searchEl.classList.remove('focused');
			this.predictionsEl.classList.remove('active');
		}, 150);
	}

	/**
	 * Check to see if a prediction from the autocomplete dropdown was clicked,
	 * and if so, trigger this.handlePredictionSelect(), passing the event target.
	 *
	 * @since 1.0.0
	 *
	 * @param {Event} e
	 */
	handlePredictionClick = (e) => {
		if (e.target.classList.contains('js-ac-prediction')) {
			this.handlePredictionSelect(e.target);
		}
	}

	/**
	 * Enabled keyboard navigation of the autocomplete dropdown. User's can use the up and down
	 * arrows to highlight the prediction, and then can hit 'enter' to select it. Hitting the
	 * 'escape' key will close the dropdown.
	 *
	 * @since 1.0.0
	 *
	 * @param {Event} e
	 */
	handleKeyboardNavigation = (e) => {
		if (this.searchEl.classList.contains('focused')) {
			switch (e.key) {
				case 'ArrowDown':
				case 'ArrowUp': {
					e.preventDefault();
					const predictionEls = this.predictionsEl.querySelectorAll('.js-ac-prediction');
					const activePredictionEl = this.predictionsEl.querySelector('.js-ac-prediction.active');

					let activeIndex = -1;
					forEach(predictionEls, (i, predictionEl) => {
						if (predictionEl === activePredictionEl) {
							activeIndex = i;
						}
					});

					/**
					 * Update activeIndex according to which arrow key was pressed.
					 */
					if (e.key === 'ArrowDown') {
						if (activeIndex === -1) {
							activeIndex = 0;
						} else if (activeIndex !== predictionEls.length - 1) {
							activeIndex += 1;
						}
					} else if (activeIndex > 0) {
						activeIndex -= 1;
					}

					/**
					 * Remove the active class from the previous prediction, and add it to the new prediction.
					 */
					activePredictionEl.classList.remove('active');
					predictionEls[activeIndex].classList.add('active');
					break;
				}

				case 'Enter': {
					this.handlePredictionSelect(this.predictionsEl.querySelector('.js-ac-prediction.active'));
					break;
				}

				case 'Escape': {
					this.searchEl.value = '';
					this.searchEl.blur();
					break;
				}
				default: {
					break;
				}
			}
		}
	}

	/**
	 * Trigger a call to this.getLocations() when a prediction from the autocomplete dropdown
	 * is selected. If the node has a lat and lng in its dataset, then make the request
	 * immediately, otherwise, use the place-id in its dataset to get the lat and lng
	 * using Google's Geocoder class.
	 *
	 * @param {Node} prediction
	 */
	handlePredictionSelect = (prediction) => {
		if (prediction) {
			this.searchEl.value = prediction.textContent;
			this.searchEl.blur();

			if (prediction.dataset.lat && prediction.dataset.lng) {
				/**
				 * If the node has a lat and lng in its dataset, then make the request immediately.
				 */
				this.getLocations(prediction.dataset.lat, prediction.dataset.lng);
			} else if (prediction.dataset.placeId) {
				/**
				 * Otherwise, use the place-id in its dataset to get the lat and lng using Google's
				 * Geocoder class.
				 */
				this.geocoder.geocode({ placeId: prediction.dataset.placeId }, (results, status) => {
					if (status === 'OK') {
						if (results[0]) {
							const { location } = results[0].geometry;
							this.getLocations(location.lat(), location.lng());
						} else {
							console.log('No results found');
						}
					} else {
						console.log(`Geocoder failed due to: ${status}`);
					}
				});
			}
		}
	}

	/**
	 * Open the appropriate share template based on the share method of the control clicked.
	 *
	 * @since 1.0.0
	 */
	handleShareControlClick = (e) => {
		const control = e.target.closest('.js-eml-share-control');
		const shareMethod = control.dataset.share;
		const perPage = this.locations.length;
		const shareUrl = `${this.settings.archiveUrl}?lat=${this.currentPosition.lat}&lng=${this.currentPosition.lng}&share=${shareMethod}&per_page=${perPage}`;

		window.open(shareUrl, '_blank');
	}

	/**
	 * Update the autocomplete search predictions using google's AutocompleteService and the
	 * FATC autocomplete api endpoint.
	 *
	 * @since 1.0.0
	 */
	updateAutocompletePredictions = () => {
		/**
		 * Add classes to searchEl and predictionsEl.
		 */
		this.searchEl.classList.add('focused');
		this.predictionsEl.classList.add('active');

		const searchValue = this.searchEl.value;

		/**
		 * Only proceed if the searchValue, when trimmed, doesn't match the previous search term.
		 * Eliminates extra requests when the user types a space.
		 */
		if (searchValue.trim() !== this.currentSearchValue) {
			/**
			 * Only proceed if the searchValue isn't an empty string to eliminate extra requests.
			 */
			if (searchValue !== '') {
				/**
				 * First, get predictions from the FATC autocomplete API. Then pass the predictions along,
				 * and get additional predictions from the Google AutocompleteService. Finally, pass all
				 * predictions on and build out the dropdown HTML.
				 *
				 * @type {Promise}
				 */
				const locatorPredictionsPromise = fetch(encodeURI(`${this.settings.apiUrl}?search=${searchValue}&per_page=3`), this.fetchHeaders)
					.then((response) => response.json());

				const autocompleteOptions = {
					input: searchValue,
					types: ['(regions)'],
				};

				if (this.settings.countryRestrictions.indexOf('none') === -1) {
					autocompleteOptions.componentRestrictions = {
						country: this.settings.countryRestrictions,
					};
				}

				const googlePredictionsPromise = new Promise((resolve) => {
					this.autocompleteService.getPlacePredictions(
						autocompleteOptions,
						(googlePredictions) => {
							resolve(googlePredictions || []);
						},
					);
				});

				Promise.all([locatorPredictionsPromise, googlePredictionsPromise])
					/**
					 * Merge Locator and Google predictions into one array and return it.
					 */
					.then((predictions) => predictions.reduce((acc, val) => acc.concat(val), []))
					.then((predictions) => {
						if (predictions.length > 0) {
							/**
							 * Placeholder for prediction dropdown HTML.
							 */
							let predictionsHtml = '';

							/**
							 * Loop through all predictions and add them to predictionsHtml.
							 */
							forEach(predictions, (i, prediction) => {
								let className = 'js-ac-prediction eml-dropdown-list__item';

								/**
								 * Add active class to the first prediction.
								 */
								if (i === 0) {
									className = `${className} active`;
								}

								if (prediction.lat && prediction.lng) {
									/**
									 * Handle locator predictions.
									 */
									predictionsHtml += `<li class="${className}" data-lat="${prediction.lat}" data-lng="${prediction.lng}">${prediction.post.post_title}</li>`;
								} else if (prediction.place_id) {
									/**
									 * Handle Google predictions.
									 */
									predictionsHtml += `<li class="${className}" data-place-id="${prediction.place_id}">${prediction.description}</li>`;
								}
							});

							/**
							 * Update the prediction dropdown's HTML.
							 */
							this.predictionsEl.innerHTML = predictionsHtml;
						} else {
							/**
							 * Add a helpful message if no results are found.
							 */
							this.predictionsEl.innerHTML = '<div id="js-eml-no-results-item" class="eml-dropdown-list__item">No results found</div>';
						}
					})
					.catch(() => {
						/**
						 * Add a helpful message if no results are found.
						 */
						this.predictionsEl.innerHTML = '<div id="js-eml-no-results-item" class="eml-dropdown-list__item">No results found</div>';
					});
			}

			/**
			 * Keep track of the new search value for later comparison.
			 */
			this.currentSearchValue = searchValue.trim();
		}
	}

	/**
	 * Determine if there are any valid search results.
	 *
	 * @since 1.0.0
	 */
	hasResults = () => {
		const noResultsItem = this.locatorEl.querySelector('.js-eml-no-results-item');
		if (noResultsItem) {
			return false;
		}

		const results = this.predictionsEl.querySelectorAll('.eml-dropdown-list__item');
		if (results.length === 0) {
			return false;
		}

		return true;
	}

	/**
	 * Show the no results message.
	 *
	 * @since 1.0.0
	 */
	showNoResultsAlert = () => {
		this.searchAlertEl.innerHTML = `<div class="alert alert--info alert--inline">
			<span class="alert__content">${this.settings.searchInputLabel}</span>
		</div>`;
	}

	/**
	 * Recenter the map on the current center.
	 *
	 * @since 1.0.0
	 */
	recenterMap = () => {
		this.map.setCenter(this.mapCenter);
	}

	/**
	 * Make a request to the WP REST API endpoint using lat and lng to return the closest locations.
	 *
	 * @since 1.0.0
	 *
	 * @param {Number} lat Latitude
	 * @param {Number} lng Longitude
	 * @param {Number} limit Number of results to return with the request.
	 */
	getLocations = (lat = false, lng = false, page = 1) => {
		let { apiUrl } = this.settings;
		if (lat || lng || page) {
			apiUrl += '?';
			const params = [];

			if (lat) {
				params.push(`lat=${lat}`);
			}

			if (lng) {
				params.push(`lng=${lng}`);
			}

			if (page) {
				params.push(`page=${page}`);
			}

			if (params.length > 0) {
				apiUrl += params.join('&');
			}
		}

		/**
		 * Fire event that lets theme developers hook in and change the apiUrl
		 * before fetching locations.
		 *
		 * @see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
		 */
		const emlPreFetchLocations = new CustomEvent('emlPreFetchLocations', {
			detail: { apiUrl },
		});

		window.dispatchEvent(emlPreFetchLocations);

		fetch(emlPreFetchLocations.detail.apiUrl, this.fetchHeaders)
			.then((response) => {
				/**
				 * Update pagination variables.
				 */
				this.pagination.totalPosts = parseInt(response.headers.get('X-WP-Total'));
				this.pagination.totalPages = parseInt(response.headers.get('X-WP-TotalPages'));

				/**
				 * Update the current page.
				 */
				this.pagination.currentPage = page;

				/**
				 * Show pagination if page is less than the total number of pages.
				 */
				forEach(this.paginationEls, (i, paginationEl) => {
					if (page < this.pagination.totalPages) {
						paginationEl.style.display = 'block';
					} else {
						paginationEl.style.display = 'none';
					}
				});

				return response.json();
			})
			.then((locations) => {
				this.locations = page === 1 ? locations : this.locations.concat(locations);
				this.currentPosition = { lat, lng };
				this.searchAlertEl.innerHTML = '';

				/**
				 * Fire event after locations are fetched.
				 *
				 * @see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
				 */
				const emlPostFetchLocations = new CustomEvent('emlPostFetchLocations', {
					detail: {
						locations: this.locations,
						lat,
						lng,
						pagination: this.pagination,
					},
				});

				window.dispatchEvent(emlPostFetchLocations);

				this.handleNewLocations();

				/**
				 * Update the email form action with the current latitude and longitude.
				 */
				if (this.emailEnabled) {
					this.emailForm.action = `${this.settings.archiveUrl}?lat=${lat}&lng=${lng}`;
				}

				forEach(this.loadMoreButtons, (i, loadMoreButton) => {
					loadMoreButton.removeAttribute('disabled');
				});
			});
	}

	/**
	 * Load more locations, if available, when the load more button is clicked.
	 *
	 * @since 1.0.0
	 */
	handleLoadMore = () => {
		/**
		 * Only load more locations if there are more to load...
		 */
		if (this.pagination.currentPage < this.pagination.totalPages) {
			forEach(this.loadMoreButtons, (i, loadMoreButton) => {
				loadMoreButton.setAttribute('disabled', 'disabled');
			});
			this.getLocations(this.currentPosition.lat, this.currentPosition.lng, this.pagination.currentPage + 1);
		}
	}

	/**
	 * Open the email form when an email form control is clicked,
	 * and update the email form's "action" attribute in the process.
	 *
	 * @since 1.0.0
	 *
	 * @param {Event} e
	 */
	handleEmailFormControlClick = (e) => {
		const control = e.target.closest('.js-eml-email-control');
		if (control) {
			this.locatorEl.classList.add('show-email-form');

			/**
			 * If this isn't a control inside of a location, reset the email form action
			 * back to the archive url to share the full list of locations.
			 */
			const location = control.closest('.eml-location');
			if (!location) {
				const { lat, lng } = this.currentPosition;
				this.emailForm.action = `${this.settings.archiveUrl}?lat=${lat}&lng=${lng}`;
			}
		}
	}

	/**
	 * Close the email form when clicking outside of it or hitting the escape key.
	 *
	 * @since 1.0.0
	 *
	 * @param {Event} e
	 */
	handleEmailFormClose = (e) => {
		const { target } = e;
		if (this.emailFormContainer === target || this.emailFormCloseButton === target) {
			this.closeEmailForm();
		}
	}

	/**
	 * Close the email form and reset any alerts.
	 *
	 * @since 1.0,0
	 */
	closeEmailForm = () => {
		this.locatorEl.classList.remove('show-email-form');
		this.resetEmailFormAlert();
	}

	/**
	 * Clear any alerts triggered by the email form.
	 *
	 * @since 1.0.0
	 */
	resetEmailFormAlert = () => {
		this.emailFormAlert.classList.remove('success');
		this.emailFormAlert.classList.remove('danger');
		this.emailFormAlert.innerHTML = '';
	}

	/**
	 * Function to run when the email form successfully submits.
	 *
	 * @since 1.0.0
	 */
	handleEmailFormSuccess = () => {
		this.emailFormAlert.classList.remove('loading');
		this.emailFormAlert.innerHTML = 'Email sent!';
	}

	/**
	 * Function to run when the email form fails to submit.
	 *
	 * @since 1.0.0
	 */
	handleEmailFormError = () => {
		this.emailFormAlert.classList.remove('success');
		this.emailFormAlert.classList.remove('loading');
		this.emailFormAlert.classList.add('danger');
		this.emailFormAlert.innerHTML = 'Email failed to send. Please try again.';
	}

	/**
	 * Use ajax to submit the form, which will trigger wp_mail() to send the email.
	 *
	 * @since 1.0.0
	 *
	 * @param {Event} e
	 */
	handleEmailFormSubmit = (e) => {
		e.preventDefault();

		/**
		 * Reset any previous alerts triggered by the email form,
		 * and show loading animation.
		 */
		this.resetEmailFormAlert();
		this.emailFormAlert.classList.add('success');
		this.emailFormAlert.classList.add('loading');
		const startTime = new Date().getTime() / 1000;

		/**
		 * Build the url to open that will trigger wp_mail().
		 */
		const data = serialize(this.emailForm);
		let url = this.emailForm.action;
		url += this.emailForm.action.indexOf('?') > -1 ? '&' : '?';
		url += data;

		fetch(url, this.fetchHeaders)
			.then((response) => response.json())
			.then((json) => {
				if (json.sent) {
					const endTime = new Date().getTime() / 1000;
					const requestDuration = endTime - startTime;

					/**
					 * Check how long the request took, and if it was less than 2 seconds,
					 * add a delay so there is some percieved delay and it looks like something
					 * happened.
					 */
					if (requestDuration < 2) {
						setTimeout(() => {
							this.handleEmailFormSuccess();
						}, 2000);
					} else {
						this.handleEmailFormSuccess();
					}
				} else {
					this.handleEmailFormError();
				}
			});
	}

	/**
	 * Add location details HTML to the mapLocationDetailsEl, slide map into view and center on pin.
	 *
	 * @since 1.0.0
	 *
	 * @param {Number} index The index of the location in this.locations
	 */
	showMapLocationDetails = (index) => {
		/**
		 * Use the passed in index value to get the location, marker, and infowindow.
		 */
		const location = this.locations[index];
		const marker = this.markers[index];
		const infowindow = this.infowindows[index];

		/**
		 * Replace the curent innerHTML with the new location's HTML.
		 */
		this.mapLocationDetailsEl.innerHTML = location.map_details_html;

		/**
		 * Add class to map container that slides the details into view.
		 */
		this.mapContainerEl.classList.add('has-details');

		/**
		 * Update the email form action, if email sharing is enabled.
		 */
		if (this.emailEnabled) {
			this.emailForm.action = location.permalink;
		}

		/**
		 * Close the active infowindow if it exists.
		 */
		if (this.activeInfowindow) {
			this.activeInfowindow.close();
		}

		/**
		 * Open the target infowindow.
		 */
		infowindow.open(this.map, marker);

		/**
		 * Set the target infowindow as the activeInfowindow.
		 */
		this.activeInfowindow = infowindow;

		/**
		 * Center map on marker and zoom in.
		 */
		this.map.setCenter(marker.getPosition());
		this.map.setZoom(14);
	}

	/**
	 * Remove class that slides location details out of view.
	 *
	 * @since 1.0.0
	 */
	hideMapLocationDetails = () => {
		this.map.fitBounds(this.map.bounds);
		this.mapContainerEl.classList.remove('has-details');
		this.activeInfowindow.close();

		/**
		 * Scroll the mapLocationDetailsEl to the top so it's posistioned properly when the next location
		 * is opened. Doing this on a timeout to avoid a flicker where the element jumps to
		 * the top before the slide transition can complete.
		 */
		setTimeout(() => {
			this.mapLocationDetailsEl.scrollTop = 0;
		}, 300);
	}

	/**
	 * Remove the current locations from the results list and their markers from the map.
	 *
	 * @since 1.0.0
	 */
	resetMap = () => {
		if (this.markers) {
			forEach(this.markers, (i, marker) => {
				marker.setMap(null);
			});

			this.markers = [];
		}

		/**
		 * Remove any added classes from the map container.
		 */
		this.mapContainerEl.classList.remove('has-details');
	}

	handleMapLocationClick = (e) => {
		const targetEl = e.target.closest('.eml-location');
		const locationEls = this.mapLocationsEl.querySelectorAll('.eml-location');

		forEach(locationEls, (i, locationEl) => {
			if (locationEl === targetEl) {
				this.showMapLocationDetails(i);
			}
		});
	}

	/**
	 * Get the HTML markup for the no results alert message.
	 *
	 * @since 1.1.0
	 */
	getNoResultsAlert = () => `<li class="eml-no-results">
		<div class="eml-alert danger">${this.settings.noResultsMessage}</div>
	</li>`;

	updateMapLocations = () => {
		/**
		 * Clear map markers and map classes before anything else.
		 */
		this.resetMap();

		/**
		 * Placeholder for storing html.
		 */
		let html = '';

		if (this.locations.length > 0) {
			/**
			 * Reset existing map bounds with new LatLngBounds object.
			 */
			this.map.bounds = new this.google.maps.LatLngBounds();

			forEach(this.locations, (i, location) => {
				/**
				 * Add marker for location.
				 */
				this.markers[i] = new this.google.maps.Marker({
					position: new this.google.maps.LatLng(location.lat, location.lng),
					map: this.map,
				});

				/**
				 * Extend the bounds to include the marker.
				 */
				this.map.bounds.extend(this.markers[i].getPosition());

				/**
				 * Setup infowindow.
				 */
				this.infowindows[i] = new this.google.maps.InfoWindow({
					content: location.post.post_title,
				});

				/**
				 * Setup event to load the location details when the marker is clicked.
				 */
				this.google.maps.event.addListener(this.markers[i], 'click', () => {
					this.showMapLocationDetails(i);
					const markerClickEvent = new CustomEvent('emlMarkerClick', {
						detail: {
							location,
							marker: this.markers[i],
							infowindow: this.infowindows[i],
						},
					});
					window.dispatchEvent(markerClickEvent);
				});

				/**
				 * Add event to hide location details and zoom out to bounds when infowindow was closed.
				 */
				this.google.maps.event.addListener(this.infowindows[i], 'closeclick', this.hideMapLocationDetails);

				/**
				 * Build location list item html.
				 */
				html += location.map_item_html;
			});

			/**
			 * Make sure all the markers fit within the bounds of the map.
			 */
			this.map.setCenter(this.map.bounds.getCenter());
			this.map.fitBounds(this.map.bounds);

			if (!this.locatorEl.classList.contains('has-results')) {
				this.locatorEl.classList.add('has-results');
			}
		} else {
			html = this.getNoResultsAlert();
		}

		/**
		 * Update the mapLocationsEl html with the new html.
		 */
		this.mapLocationsEl.innerHTML = html;
	}

	updateListLocations = () => {
		let html = '';

		if (this.locations.length > 0) {
			forEach(this.locations, (i, location) => {
				html += location.list_item_html;
			});
		} else {
			html = this.getNoResultsAlert();
		}

		this.listLocationsEl.innerHTML = html;
	}

	/**
	 * Use the results returned from the REST API to render the closest
	 * locations to the map, removing previously displayed locations.
	 *
	 * @since 1.0.0
	 */
	handleNewLocations = () => {
		if (this.isMapEnabled()) {
			this.updateMapLocations();
		}

		if (this.isListEnabled()) {
			this.updateListLocations();
		}
	}

	handleResetClick = (e) => {
		e.preventDefault();
		this.resetMap();
	}

	/**
	 * Helper function to check if the map layout is enabled.
	 *
	 * @since 1.1.0
	 */
	isMapEnabled = () => !!this.mapContainerEl;

	/**
	 * Helper function to check if the list layout is enabled.
	 *
	 * @since 1.1.0
	 */
	isListEnabled = () => !!this.listLocationsEl;

	/**
	 * Get the current latitude coordinate.
	 *
	 * @since 1.1.0
	 */
	getLat = () => {
		if (this.currentPosition.lat || (this.userPosition && this.userPosition.lat)) {
			return this.currentPosition.lat ? this.currentPosition.lat : this.userPosition.lat;
		}
		return false;
	}

	/**
	 * Get the current longitude coordinate.
	 *
	 * @since 1.1.0
	 */
	getLng = () => {
		if (this.currentPosition.lng || (this.userPosition && this.userPosition.lng)) {
			return this.currentPosition.lng ? this.currentPosition.lng : this.userPosition.lng;
		}
		return false;
	}
}
