import { forEach } from '../../includes/js/helpers';
import EmLocator from './modules/EmLocator';

document.addEventListener('DOMContentLoaded', () => {
	window.emlLocators = [];
	const locatorEls = document.querySelectorAll('.js-eml-locator');
	forEach(locatorEls, (i, locatorEl) => {
		const key = locatorEl.id.replace('js-eml-locator-', '');
		window.emlLocators[key] = new EmLocator(locatorEl);
	});
});
