/**
 * Loop through an array/nodelist and call the passed callback function.
 *
 * @since 1.0.0
 *
 * @param  {Array}   	array    	Array to be looped over.
 * @param  {Function} 	callback 	Callback function.
 * @param  {???}   		scope
 */
export function forEach(array, callback, scope) {
	for (let i = 0; i < array.length; i++) {
		callback.call(scope, i, array[i]);
	}
}
